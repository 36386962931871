import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

import de from 'vuetify/lib/locale/de'

export default new Vuetify({
    lang: {
        locales: { de },
        current: 'de',
      },
    theme: {
      themes: {
        light: {
          primary: '#003A40',
          secondary: '#00965E',
          accent: '#F1C400',
          error: '#E73F0C',
          darklagoon: '#009390',
          amarillo: '#f1c400',
        },
      },
    },
});