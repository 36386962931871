import axios from "axios";

const state = getDefaultState();

function getDefaultState() {
  return {
    fachfunktionen: []
  };
}

const mutations = {
  SET_FACHFUNKTIONEN(state, payload) {
    state.fachfunktionen = payload;
  }, RESET: state => {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  getFachFunktionen({ commit }) {
    axios.get(process.env.VUE_APP_AIS_BASE_URL_OLD + "/fachfunktionen").then(response => {
      commit("SET_FACHFUNKTIONEN", response.data);
    });
  }
};

const getters = {
  fachfunktionen: state => {
    return state.fachfunktionen;
  }
};

const fachfunktionenModule = {
  state,
  mutations,
  actions,
  getters
};

export default fachfunktionenModule;
