import axios from "axios";

const state = getDefaultState();

function getDefaultState() {
  return {
    systems: [],
    current_system: {
      cots: {
        hersteller: "",
        lizenzModel: "",
        supportVorhanden: false
      }      
    }
  };
}

const mutations = {
  SET_SYSTEMS(state, payload) {
    state.systems = payload;
  },
  SET_CURRENT_SYSTEM: (state, system) => {
    state.current_system = system;
  },
  RESET: state => {
    Object.assign(state, getDefaultState());
  },
  RESET_SYSTEMTYP(state) {
    let typ = parseInt(state.current_system.systemTyp);
    switch (typ) {
      //Eigenentwicklung
      case 0:
        state.current_system.ossLizenzen = [];
        state.current_system.cots = {};
        state.current_system.ossUrl = "";
        break;
      //COTS
      case 1:
        state.current_system.programmierSprachen = [];
        state.current_system.ossLizenzen = [];
        state.current_system.ossUrl = "";
        break;
      //OSS
      case 2:
        state.current_system.programmierSprachen = null;
        state.current_system.cots = {};
        break;
    }
  },
  updateArchitect(state, architect) {
    state.current_system.architekt = architect;
  },
  updateBezeichnung(state, bezeichnung) {
    state.current_system.bezeichnung = bezeichnung;
  },
  updateSystemtyp(state, systemtyp) {
    state.current_system.systemTyp = systemtyp;
  },
  updateArchitekturtyp(state, arctyp) {
    state.current_system.architekturTyp = arctyp;
  },
  updateFachAbteilung(state, fachabteilung) {
    state.current_system.fachAbteilung = fachabteilung;
  },
  updateUnterstuetzeFunktionen(state, unterstuetzeFunktionen) {
    state.current_system.unterstuetzeFunktionen = unterstuetzeFunktionen;
  },
  updateBeschreibung(state, beschreibung) {
    state.current_system.beschreibung = beschreibung;
  },
  updateOssLizenzen(state, osslizenzen) {
    state.current_system.ossLizenzen = osslizenzen;
  },
  updateProgrammierSprachen(state, programmiersprachen) {
    state.current_system.programmierSprachen = programmiersprachen;
  },
  updateCotsHersteller(state, hersteller) {
    state.current_system.cots.hersteller = hersteller;
  },
  updateCotsLizenzModel(state, lizenzmodel) {
    state.current_system.cots.lizenzModel = lizenzmodel;
  },
  updateCotsSupportVorhanden(state, supportVorhanden) {
    state.current_system.cots.supportVorhanden = supportVorhanden;
  },
  updateEigententwicklung(state, eigententwicklung) {
    state.current_system.eigententwicklung = eigententwicklung;
  },
  updateOssUrl(state, ossUrl) {
    state.current_system.ossUrl = ossUrl;
  }
};

const actions = {
  getSystems({ commit }) {
    axios.get(process.env.VUE_APP_AIS_BASE_URL_OLD + "/systems").then(response => {
      commit("SET_SYSTEMS", response.data);
    });
  },
  registerSystem: ({ commit }, system) => {
    commit("SET_CURRENT_SYSTEM", system);
  },
  resetSystemTyp({ commit }) {
    commit("RESET_SYSTEMTYP");
  },
  updateCurrentSystem({ commit }) {
    return axios
      .post(
        process.env.VUE_APP_AIS_BASE_URL_OLD + "/systems",
        this.getters.currentSystem
      )
      .then(response => {
        if (response.status === "success") {
          commit("SET_CURRENT_SYSTEM", response.data);
        }
        return response;
      })
      .catch(err => {
        return err.response;
      });
  }
};

const getters = {
  systeme: state => {
    return state.systems;
  },
  currentSystemBezeichnung: state => {
    return state.current_system.bezeichnung;
  },
  currentSystemId: state => {
    return state.current_system.id;
  },
  currentSystem: state => {
    return state.current_system;
  },
  cots: state => {
    return state.current_system.cots;
  }
};

const systemsModule = {
  state,
  mutations,
  actions,
  getters
};

export default systemsModule;
