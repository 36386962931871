import axios from "axios";

const state = getDefaultState();

function getDefaultState() {
  return {
    programmiersprachen: []
  };
}

const mutations = {
  SET_PROGRAMMIERSPRACHEN(state, payload) {
    state.programmiersprachen = payload;
  }, RESET: state => {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  getFachFunktionen({ commit }) {
    axios.get(process.env.VUE_APP_AIS_BASE_URL_OLD + "/programmiersprachen").then(response => {
      commit("SET_PROGRAMMIERSPRACHEN", response.data);
    });
  }
};

const getters = {
  programmiersprachen: state => {
    return state.programmiersprachen;
  }
};

const programmiersprachenModule = {
  state,
  mutations,
  actions,
  getters
};

export default programmiersprachenModule;
