import axios from "axios";

const state = getDefaultState();

function getDefaultState() {
  return {
    produktgruppen: []
  };
}

const mutations = {
  SET_PRODUKTGRUPPEN(state, payload) {
    state.produktgruppen = payload;
  }, RESET: state => {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  getProduktgruppen({ commit }) {
    axios.get(process.env.VUE_APP_AIS_BASE_URL_OLD + "/produktgruppe").then(response => {
      commit("SET_PRODUKTGRUPPEN", response.data);
    });
  }
};

const getters = {
  produktgruppen: state => {    
    return state.produktgruppen;
  }
};

const produktgruppenModule = {
  state,
  mutations,
  actions,
  getters
};

export default produktgruppenModule;
