import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store'
import Keycloak from 'keycloak-js'
import "./plugins/vuetify-number.js";
import i18n from './i18n'

Vue.config.productionTip = false

let initOptions = {
  url: process.env.VUE_APP_OPENID_URL,
  realm: process.env.VUE_APP_OPENID_REALM,
  clientId: process.env.VUE_APP_OPENID_CLIENTID,
  onLoad: process.env.VUE_APP_OPENID_ONLOAD
}

let keycloak = Keycloak(initOptions);
Vue.prototype.$keycloak = keycloak;

keycloak.init({ onLoad: initOptions.onLoad }).then((auth) => {
  if (!auth) {
    window.location.reload();
  } else {
    var token = keycloak.token;
    let user;
    let username;
    let roles = keycloak.realmAccess.roles;
    if (keycloak.idToken) {
      user = keycloak.idTokenParsed.preferred_username;
      username = keycloak.idTokenParsed.family_name + ', ' + keycloak.idTokenParsed.given_name;
    } else {
      keycloak.loadUserProfile(function () {
        user = keycloak.profile.username;
        username = keycloak.profile.lastName + ', ' + keycloak.profile.firstName;
      }, function () {
        console.log('Failed to retrieve user details. Please enable claims or account role');
      });
    }
    store.dispatch("login", { token, user, username, roles });
    new Vue({
      router,
      vuetify,
      store,
      i18n,
      render: h => h(App, { props: { keycloak: keycloak } })
    }).$mount('#app');
  }
  //Token Refresh
  setInterval(() => {
    keycloak.updateToken(70).then((refreshed) => {
      if (refreshed) {
        console.log('Token refreshed' + refreshed);
        var token = keycloak.token;
        store.dispatch("refreshToken", { token });
      } else {
        //console.log('Token not refreshed, valid for '
        //  + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
      }
      if(store.getters.isLoggedIn === ''){
        keycloak.logout();
      }
    }).catch((e) => {
      console.log(e.message);
      console.log('Failed to refresh token');
    });
  }, 6000)

}).catch((e) => {
  console.log(e.message);
  console.log("Authenticated Failed");
});