import axios from "axios";

const state = getDefaultState();

function getDefaultState() {
  return {
    lizenzen: []
  };
}

const mutations = {
  SET_LIZENZEN(state, payload) {
    state.lizenzen = payload;
  }, RESET: state => {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  getOssLizenzen({ commit }) {
    axios.get(process.env.VUE_APP_AIS_BASE_URL_OLD + "/osslizenzen").then(response => {
      commit("SET_LIZENZEN", response.data);
    });
  }
};

const getters = {
  lizenzen: state => {
    return state.lizenzen;
  }
};

const lizenzenModule = {
  state,
  mutations,
  actions,
  getters
};

export default lizenzenModule;
